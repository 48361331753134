<template>
  <div v-if="products.length" class="overflow-hidden lg:py-10 py-8">
    <div class="container">
      <slot>
        <h2 class="beta mb-6">
          {{ props.carouselTitle }}
        </h2>
      </slot>
      <ClientOnly>
        <ProductCarousel swiper-classes="overflow-visible xl:overflow-hidden">
          <SwiperSlide
            v-for="tileProduct in products"
            :key="tileProduct.slug"
            class="h-auto"
          >
            <ProductTile :product="tileProduct" class="w-auto h-full" />
          </SwiperSlide>
        </ProductCarousel>
        <template #placeholder>
          <div class="h-[430px] md:h-[402px] xl:h-[476px] bg-white animate-fadepulse" />
        </template>
      </ClientOnly>
    </div>
  </div>
</template>

<script setup lang="ts">
import ProductTile from '~/components/category/ProductTile.vue'
import ProductCarousel from '~/components/carousel/ProductCarousel.vue'
import { attributesForProductTile } from '~/helpers/instantsearch'
import type { ProductAlgolia } from '~/types/product'

const props = defineProps({
  itemIds: {
    type: Array as () => number[],
    required: false,
    default: () => []
  },
  searchKey: {
    type: String,
    required: true
  },
  carouselTitle: {
    type: String,
    required: true
  },
  hitsPerPage: {
    type: Number,
    default: 8
  },
  productSearchQuery: {
    type: String,
    required: false,
    default: ''
  }
})
const products = ref<ProductAlgolia[]>([])

const filters = props.productSearchQuery || (props.itemIds?.length ? props.itemIds.map(id => `itemId:${id}`).join(' OR ') : '')
if (filters) {
  const { data } = await useAsyncAlgoliaSearch({
    key: props.searchKey,
    query: '',
    requestOptions: {
      filters,
      distinct: true,
      attributesToRetrieve: attributesForProductTile,
      attributesToHighlight: [],
      hitsPerPage: props.hitsPerPage
    }
  })
  products.value = data.value?.hits as ProductAlgolia[]
}
</script>
